"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeAllOfToJSON = exports.ChallengeAllOfFromJSONTyped = exports.ChallengeAllOfFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ChallengeAllOfFromJSON(json) {
    return ChallengeAllOfFromJSONTyped(json, false);
}
exports.ChallengeAllOfFromJSON = ChallengeAllOfFromJSON;
function ChallengeAllOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'description': json['description'],
        'backgroundImageLarge': !(0, runtime_1.exists)(json, 'backgroundImageLarge') ? undefined : (0, _1.AssetFromJSON)(json['backgroundImageLarge']),
        'backgroundImageSmall': !(0, runtime_1.exists)(json, 'backgroundImageSmall') ? undefined : (0, _1.AssetFromJSON)(json['backgroundImageSmall']),
        'brandLogo': !(0, runtime_1.exists)(json, 'brandLogo') ? undefined : (0, _1.AssetFromJSON)(json['brandLogo']),
        'brandColor': !(0, runtime_1.exists)(json, 'brandColor') ? undefined : json['brandColor'],
        'rewards': (json['rewards'].map(_1.ChallengeRewardFromJSON)),
        'start': json['start'],
        'end': json['end'],
        'createdAt': !(0, runtime_1.exists)(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !(0, runtime_1.exists)(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}
exports.ChallengeAllOfFromJSONTyped = ChallengeAllOfFromJSONTyped;
function ChallengeAllOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'description': value.description,
        'backgroundImageLarge': (0, _1.AssetToJSON)(value.backgroundImageLarge),
        'backgroundImageSmall': (0, _1.AssetToJSON)(value.backgroundImageSmall),
        'brandLogo': (0, _1.AssetToJSON)(value.brandLogo),
        'brandColor': value.brandColor,
        'rewards': (value.rewards.map(_1.ChallengeRewardToJSON)),
        'start': value.start,
        'end': value.end,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
    };
}
exports.ChallengeAllOfToJSON = ChallengeAllOfToJSON;
