"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeMembershipToJSON = exports.ChallengeMembershipFromJSONTyped = exports.ChallengeMembershipFromJSON = void 0;
var _1 = require("./");
function ChallengeMembershipFromJSON(json) {
    return ChallengeMembershipFromJSONTyped(json, false);
}
exports.ChallengeMembershipFromJSON = ChallengeMembershipFromJSON;
function ChallengeMembershipFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': json['userId'],
        'challenge': (0, _1.ChallengeFromJSON)(json['challenge']),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'id': json['id'],
    };
}
exports.ChallengeMembershipFromJSONTyped = ChallengeMembershipFromJSONTyped;
function ChallengeMembershipToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
        'challenge': (0, _1.ChallengeToJSON)(value.challenge),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
    };
}
exports.ChallengeMembershipToJSON = ChallengeMembershipToJSON;
