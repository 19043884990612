"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChallengeStatToJSON = exports.ChallengeStatFromJSONTyped = exports.ChallengeStatFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ChallengeStatFromJSON(json) {
    return ChallengeStatFromJSONTyped(json, false);
}
exports.ChallengeStatFromJSON = ChallengeStatFromJSON;
function ChallengeStatFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'userId': json['userId'],
        'challenge': (0, _1.ChallengeFromJSON)(json['challenge']),
        'progress': json['progress'],
        'target': json['target'],
        'isClaimed': json['isClaimed'],
        'isWinner': !(0, runtime_1.exists)(json, 'isWinner') ? undefined : json['isWinner'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'id': json['id'],
    };
}
exports.ChallengeStatFromJSONTyped = ChallengeStatFromJSONTyped;
function ChallengeStatToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'userId': value.userId,
        'challenge': (0, _1.ChallengeToJSON)(value.challenge),
        'progress': value.progress,
        'target': value.target,
        'isClaimed': value.isClaimed,
        'isWinner': value.isWinner,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
        'id': value.id,
    };
}
exports.ChallengeStatToJSON = ChallengeStatToJSON;
